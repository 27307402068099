
// fonts: https://fonts.google.com/specimen/Poppins?sidebar.open&selection.family=Poppins:wght@200;600
// color scheme: https://coolors.co/2f4858-ffca3a-d8dbe2-2e1f27-eeeeee

import React, { useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components';
import { Link, graphql } from 'gatsby'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { MDXProvider } from '@mdx-js/react'
import DotIcon from '../../components/DotIcon'
import { mobileOnlyRule } from '../../components/Breakpoints'

const tufts_blue = `#0591e9`
const sunglow = `#ffca3a`
const gainsboro = `#d8dbe2`
const black_coffee = `#2e1f27`
const black_coffee_light = `#5e4f57`
const white_e = `#eeeeee`
const princeton_orange = `#f77d19`
const passive_grey = `#999999`

// light theme
const white = `#ffffff`
const granite = `#616163`
const sea_green = `#44ffd2`
const electric_blue = `#87f6ff`
const peach = `#ffbfa0`

/*
const bgColor = white;
const highlightColor = peach;
const fgColor = granite;
const fg2Color = white;
const bg2Color = electric_blue;
*/

const bgColor = white;
const highlightColor = princeton_orange;
const fgColor = black_coffee;
const mainFont = `'Georgia', sans-serif`;

const fgSubColor = black_coffee_light;
const fg2Color = tufts_blue;
const bg2Color = white;
const fg3Color = passive_grey;



/*
// dark theme - blue & brown
const bgColor = `#2f4858ff`
const highlightColor = `#ffca3aff`
const lightColor = `#5c9eadff`
const bg2Color = `#2e1f27ff`
const fgColor = `#eeeeeeff`
const fg2Color = `#D8DBE2`
const mainFont = `'Poppins', sans-serif`;
*/

/*
$gradient-top: linear-gradient(0deg, #2f4858ff, #ffca3aff, #5c9eadff, #2e1f27ff, #eeeeeeff);
$gradient-right: linear-gradient(90deg, #2f4858ff, #ffca3aff, #5c9eadff, #2e1f27ff, #eeeeeeff);
$gradient-bottom: linear-gradient(180deg, #2f4858ff, #ffca3aff, #5c9eadff, #2e1f27ff, #eeeeeeff);
$gradient-left: linear-gradient(270deg, #2f4858ff, #ffca3aff, #5c9eadff, #2e1f27ff, #eeeeeeff);
$gradient-top-right: linear-gradient(45deg, #2f4858ff, #ffca3aff, #5c9eadff, #2e1f27ff, #eeeeeeff);
$gradient-bottom-right: linear-gradient(135deg, #2f4858ff, #ffca3aff, #5c9eadff, #2e1f27ff, #eeeeeeff);
$gradient-top-left: linear-gradient(225deg, #2f4858ff, #ffca3aff, #5c9eadff, #2e1f27ff, #eeeeeeff);
$gradient-bottom-left: linear-gradient(315deg, #2f4858ff, #ffca3aff, #5c9eadff, #2e1f27ff, #eeeeeeff);
$gradient-radial: radial-gradient(#2f4858ff, #ffca3aff, #5c9eadff, #2e1f27ff, #eeeeeeff);
*/

const GlobalStyle = createGlobalStyle`
body {
    background: ${bgColor};
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

a {
  color: inherit;
  cursor: pointer;
}


p, ol, ul, blockquote, pre, code {
  line-height: 1.5em;
  margin-bottom: 1.5em;
}

br {
  height: 0.8em;
  margin: 20px;
  font-size: .8em;
}

h1 {
  font-size: 3em;

  ${mobileOnlyRule} {
    font-size: 2em;
  }
}

h2 {
  font-size: 2em;

  ${mobileOnlyRule} {
    font-size: 1.5em;
  }

  line-height: 0.75em;
  text-decoration: underline;
  text-decoration-color: ${highlightColor};
  margin: 30px 0 30px 0;
}

h2 {
}

h1, h2, h3 {
  font-weight: 600;
}

ul {
  list-style: none;
}

li {
  margin: 5px;
  color: ${fgSubColor};
}

i {
  color: ${fgSubColor};
}
`
const ContentsStyle = styled.div`

a {
  text-decoration-color: ${highlightColor};
}

margin: 20px;
color: ${fgColor};
font-family: ${mainFont};
font-weight: 300;
font-size: 1.3em;
max-width: 1200px;


`;

const HeaderStyle = styled.div`
background-color: ${bg2Color};
padding: 20px;
display: flex;
justify-content: space-between;
align-items: center;
a {
  text-decoration: none;
}
`

const HeaderTitle = styled.span`
color: ${fg2Color};
font-size: 2em;
font-family: ${mainFont};
font-weight: 600;
`

const HeaderRightMenu = styled.div`
  color: ${fg2Color};
font-size: 1.4em;
font-family: ${mainFont};
font-weight: 600;
display: flex;
align-items: center;

span {
  margin: 10px;
  ${mobileOnlyRule} {
    margin: 6px;
  }
}

a {
  margin-left: 5px;
}

`
const FooterTextStyle = styled.div`
display: flex;
background-color: ${bg2Color};
padding: 20px;
color: ${fg3Color};
font-size: 1em;
font-weight: 300;
font-family: ${mainFont};

${mobileOnlyRule} {
  padding: 10px;
  font-size: .7em;
}
`

const IconGroup = styled.span`
display: flex;
align-items: center;

`

export function TwitterLink({children}) {
    return <a href="https://twitter.com/ndyfschr">{children}</a>
}

export function LinkedInLink({children}) {
    return <a href="https://www.linkedin.com/in/andy-fischer-4510945/">{children}</a>
}

export function GithubLink({children}) {
    return <a href="https://github.com/andyfischer">{children}</a>
}

export function TwitterIcon() {
    return <img
      width="40px"
      src="/img/cv-icons/twitter.png" />
}

export function LinkedInIcon() {
    return <img
      width="40px"
      src="/img/cv-icons/linkedin.png" />
}

export function GithubIcon() {
    return <img
      width="30px"
      src="/img/cv-icons/GitHub-Mark-64px.png" />
}

function Header() {
    return <HeaderStyle>
      <HeaderTitle><a href="/">Andrew Fischer</a></HeaderTitle>

      <HeaderRightMenu>
        <span><a href="/">About</a></span>
        <span><a href="/cv">CV</a></span>
      
      <IconGroup>
          <GithubLink>
            <GithubIcon />
          </GithubLink>
          <TwitterLink>
            <TwitterIcon />
          </TwitterLink>
          <LinkedInLink>
            <LinkedInIcon />
          </LinkedInLink>
      </IconGroup>
      </HeaderRightMenu>
    </HeaderStyle>
}

let nextKey = 1;
function key() {
    nextKey += 1;
    return nextKey;
}

const builtUsing = [
    <a key={key()} href="https://www.netlify.com/">Netlify</a>,
    <a key={key()} href="https://www.gatsbyjs.org/">Gatsby</a>,
    <a key={key()} href="https://reactjs.org/">React</a>,
    <a key={key()} href="https://mdxjs.com/">MDX</a>,
    <a key={key()} href="https://styled-components.com/">Styled Components</a>,
    <a key={key()} href="https://github.com/jossmac/react-images">react-images</a>,
];

function listJoin(items, joiner) {
    const out = [];
    for (let i = 0; i < items.length; i++) {
        out.push(items[i]);
        if (i < items.length - 1)
            out.push(joiner());
    }
    return out;
}


async function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function Footer() {
    return <div>
      <hr />
    <FooterTextStyle>
      This site built using&nbsp;{ listJoin(builtUsing, () => <span key={key()}>,&nbsp;</span>) }
    </FooterTextStyle>
    </div>
}

const ImageRowStyle = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const ImageRowTile = styled.div`
    height: 140px;
    width: 200px;
    display: inline-block;
    margin-left: 5px;
    background-size: cover;
    background-image: url(${props => props.src});
    cursor: pointer;
`;

export function ImageRow({imageSrcs}) {

    const [ modalOpen, setModelOpen ] = useState(false);

    function onClick(i) {
        setModelOpen({ index: i });
    }

    return <ImageRowStyle>
    <ModalGateway>
    { modalOpen && <Modal onClose={() => setModelOpen(false)}>
        <Carousel
          currentIndex={modalOpen.index}
          views={imageSrcs.map(src => ({src}))} />
    </Modal>
    }
    </ModalGateway>
    {imageSrcs.map((src, i) => <ImageRowTile
       onClick={() => onClick(i)}
       src={src}
       key={i} />
    )}
    </ImageRowStyle>
}

export function Li({children}) {
    return <li><DotIcon />{children}</li>
}

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 20px;
`

export default function Layout({children}) {
    return (
    <MDXProvider
      components={{
        li: Li
      }}>
        <GlobalStyle / >
        <Header />
        <ContentsStyle>
            <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;600"
                rel="stylesheet"/>
            <style>{`
            `}</style>
            {children}
        </ContentsStyle>
        <Footer/>
    </MDXProvider>);
}

