
import React from 'react'
import styled from 'styled-components';
import Layout, { Grid } from './index'
import { mobileOnlyRule } from '../../components/Breakpoints'

const ProfileImg = styled.img`
    object-fit: contain;
    width: 100%;
    max-width: 400px;
    justify-self: center;
    align-self: center;

    grid-column: span 4;
    ${mobileOnlyRule} {
        grid-column: span 12;
    }
`;

const TextSection = styled.div`
    grid-column: span 8;
    ${mobileOnlyRule} {
        grid-column: span 12;
    }
`

export default ({children}) => {

  return <Layout>
    <Grid>
      <ProfileImg alt="profile picture"
        src="/img/andy_profile.jpg" />
      <TextSection>
      {children}
      </TextSection>
    </Grid>
  </Layout>

}
